import React, { PropsWithChildren } from "react";
import { CssBaseline } from "@mui/material";
import { GatsbyBrowser } from "gatsby";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { QueryClientProvider, QueryClient } from "react-query";
import { Helmet } from "react-helmet";

import { useCustomTheme } from "@styles/theme";
import "@styles/global.css";

const qClient = new QueryClient();

function App(props: PropsWithChildren<any>) {
  const theme = useCustomTheme();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={qClient}>
          {props.children}
        </QueryClientProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const WrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Helmet>
      <CssBaseline />
      <App>{element}</App>
    </>
  );
};

export default WrapRootElement;
