import { useMemo } from "react";
import { createTheme, ThemeOptions } from "@mui/material/styles";

const themeOpt: ThemeOptions = {
  palette: {
    primary: {
      main: "#4AA2A2",
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: "Roboto",
    h5: {
      fontWeight: 900,
    },
    h6: {
      fontWeight: 700,
    },
    subtitle1: {
      fontWeight: 700,
    },
    subtitle2: {
      fontWeight: 700,
    },
  },
};

function useCustomTheme() {
  const theme = useMemo(() => createTheme(themeOpt), []);
  return theme;
}

export { useCustomTheme };
